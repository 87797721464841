import { MARKER_CHAR, MARKER_CHARS } from '../global/constants';
import {
  Feedback,
  FeedbackLocation,
  IHighlightElement,
} from '../global/interfaces';
import { FeedbackType } from '../global/enums';
import { filterDuplicateFeedbacks } from './feedback-helper';
import _ from 'lodash';

export function removeMarkers(textWithMarkers: string): string {
  // this can go wrong if the user inputs the marker chars, which should usually not happen
  return textWithMarkers.replace(new RegExp(MARKER_CHAR, 'g'), '');
}

/**
 *  Inserts the Marker string into the text onto the right locations. This string is probably just whitespaces
 *  so the background css image has place to show on the whitespaces.
 * @param plainText the plain text without markers
 * @param allFeedbacks all of the feedbacks
 * @returns A text with inserted markers (magic char)
 */
export function insertMarkers(
  plainText: string,
  allFeedbacks: Feedback[]
): string {
  // the length of the magic char is very important.
  filterDuplicateFeedbacks(allFeedbacks)
    .filter(
      (feedback) =>
        feedback.kind === FeedbackType.Marker &&
        // was: feedback.isRemoved === false || feedback.isRemoved === undefined
        !feedback.isRemoved
    )
    .forEach(
      (feedback, index) =>
        (plainText =
          plainText.substring(
            0,
            feedback.loc.start + index * MARKER_CHARS.length
          ) +
          MARKER_CHARS +
          plainText.substring(feedback.loc.start + index * MARKER_CHARS.length))
    );
  return plainText;
}

export function getNumberOfRegexOccurences(text: string, regex: RegExp) {
  return (text.match(regex) || []).length;
}
/**
 * Gets the marker position.
 * Usage example: Look if the changed index is inside the marker, then remove the marker
 * @param plainText The plain text without markers
 * @param _markerFeedbacks feedbacks (filtered or unfiltered doesnt matter, it gets filtered here)
 */
export function getMarkerPositions(
  plainText: string,
  _markerFeedbacks: Feedback[]
): FeedbackLocation[] {
  return filterDuplicateFeedbacks(_markerFeedbacks)
    .filter(
      (feedback) =>
        feedback.kind === FeedbackType.Marker &&
        // was: feedback.isRemoved === false || feedback.isRemoved === undefined
        !feedback.isRemoved
    )
    .map((feedback, index) => ({
      start: feedback.loc.start + index * MARKER_CHARS.length,
      end: feedback.loc.start + index * MARKER_CHARS.length,
    }));
}

export function removeAffectedMarkers(
  text: string,
  _feedbacks: Feedback[],
  location: number,
  changedCharAmount: number
) {
  // the length of the magic char is very important.
  return filterDuplicateFeedbacks(_feedbacks)
    .filter(
      (feedback) =>
        feedback.kind === FeedbackType.Marker &&
        // was: feedback.isRemoved === false || feedback.isRemoved === undefined
        !feedback.isRemoved
    )
    .map((feedback, index) => {
      if (changedCharAmount > 0) {
        if (
          location + index * MARKER_CHARS.length >=
            feedback.loc.start + (index + 1) * MARKER_CHARS.length &&
          location <= feedback.loc.start
        ) {
          feedback.isRemoved = true;
        }
      }
      return feedback;
    });
}

/**
 * This function wraps the MARKER_CHARS with the <marker> tag, so it then can be rendered with the css marker property.
 * @param inputText This text should already contain the MARKER_CHARS
 * @param markers This text should already contain the MARKER_CHARS
 */
export function getMarkerText(inputText: string) {
  //const markers = filterDuplicateFeedbacks(feedbacks).filter((feedback) => FeedbackType.Marker === feedback.kind)
  const markeredText = inputText.replace(
    new RegExp(MARKER_CHARS, 'g'),
    `<marker>${MARKER_CHARS}</marker>`
  );
  return markeredText;
}

/**
 * This insert the highlights into the text (the <mark> tags).
 * If the there are MARKER highlight only markers will be inserted. The markers should be inserted before.
 * This means the input Text should contain the MARKER_CHAR already, because this function only wraps this chat in
 * <marker> tags which then will be rendered with a background image of the orange marker.
 * @param inputText The text with already inserted markers.
 * @param _highlights The highlights (filtered feedbacks), should be of one type.
 * If even one of them is marker highlight, the feedbacks get ignored and the MARKER_CHARS gets replaced.
 */
export function getBackgroundText(inputText: string, feedbacks: Feedback[]) {
  const highlights = adjustHighlights(feedbacks);

  const highlightElements: IHighlightElement[] = highlights
    .filter((feedback) => !feedback.isRemoved)
    .map((feedback, index) => {
      return [
        {
          isStart: true,
          key: 'S' + index,
          position: feedback.loc.start,
        },
        {
          isStart: false,
          key: 'E' + index,
          position: feedback.loc.end,
        },
      ];
    })
    .flat()
    .sort((element1, element2) => element1.position - element2.position);
  let returnText = inputText.substring(
    0,
    highlightElements[0]?.position ?? inputText.length
  );

  for (let i = 0; i < highlightElements.length; i++) {
    returnText = returnText
      .concat(highlightElements[i].isStart ? '<mark>' : '</mark>')
      .concat(
        inputText.substring(
          highlightElements[i].position,
          highlightElements[i + 1]?.position ?? inputText.length
        )
      );
  }
  return getMarkerText(returnText.replace(/\n$/g, '\n\n'));
}

export function getFirstDiffIndex(s1: string, s2: string): number {
  const minLength = Math.min(s1.length, s2.length);
  for (let index = 0; index < minLength; index++) {
    if (s1[index] !== s2[index]) return index;
  }
  return minLength;
}

/**
 * Returns if the first param marker was affected, through deletion or because the cursor was inside the marker
 * @param marker the (maybe) affected marker
 * @param text the clean text without markers
 * @param markers all the markers
 * @param location the position of the cursor event
 * @param changedAmount the amount of character added/removed, if amount is positive then characters were added, else they were removed.
 */
export function isMarkerAffected(
  marker: Feedback,
  text: string,
  markers: Feedback[],
  location: number,
  changedAmount: number
) {
  for (let i = 0; i < markers.length; i++) {
    if (_.isEqual(markers[i], marker)) {
      // Get the marker interval
      const markerLocationInterval = {
        start: marker.loc.start + i * MARKER_CHARS.length,
        end: marker.loc.start + (i + 1) * MARKER_CHARS.length,
      };
      // if the location was inside the marker
      if (
        location >= markerLocationInterval.start &&
        location <= markerLocationInterval.end
      ) {
        return true;
      }
      // if the marker position was removed
      if (
        changedAmount < 0 &&
        location <= markerLocationInterval.end &&
        location + Math.abs(changedAmount) >= markerLocationInterval.start
      ) {
        return true;
      }
    }
  }

  return false;
}

/**
 * Looks if the given highlight was affected by the user (e.g. deleted)
 * @param highlight - The (maybe) affected highlight
 * @param text - The text without markers in it
 * @param feedbacks - All of the feedbacks
 * @param location - The location of the change
 * @param changedAmount - The changed amount
 * @return true if the highlight was affected and false if the highlight was not.
 */
export function isHighlightAffected(
  highlight: Feedback,
  text: string,
  feedbacks: Feedback[],
  location: number,
  changedAmount: number
) {
  const markers = feedbacks.filter((f) => f.kind === FeedbackType.Marker);
  const adjustedHighlight: Feedback = adjustHighlight(highlight, markers);

  if (changedAmount > 0) {
    // chars were added, so the only way a highlight is affected is if the location is inside the highlight.
    if (
      adjustedHighlight.loc.start <= location &&
      location <= adjustedHighlight.loc.end
    ) {
      return true;
    }
  } else {
    const locationEnd = location + Math.abs(changedAmount);
    // chars were deleted
    if (
      adjustedHighlight.loc.start <= location &&
      adjustedHighlight.loc.end >= location
    ) {
      return true;
    } else if (
      adjustedHighlight.loc.start >= locationEnd &&
      adjustedHighlight.loc.start <= locationEnd
    ) {
      return true;
    } else if (
      adjustedHighlight.loc.start >= location &&
      adjustedHighlight.loc.end <= locationEnd
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Counts the number of markers before the given position
 * @param markers - An array of feedbacks/markers
 * @param position - The position to reference from
 */
export function getNumberOfMarkersBeforePos(
  markers: Feedback[],
  position: number
): number {
  return markers
    .filter((f) => f.kind === FeedbackType.Marker)
    .filter((f) => f.loc.start < position).length;
}

export function _markersAfterPos(
  markers: Feedback[],
  position: number
): number {
  return markers
    .filter((f) => f.kind === FeedbackType.Marker)
    .filter((f) => f.loc.start > position).length;
}

/**
 * Adjusts the highlight location depending on the markers, because the markers change the text, so the location of the
 * highlight have to be adjusted
 * @param feedbacks - All of the feedbacks (all of the marker and all of the highlights)
 * @return list of the adjusted highlights, they should only be used for the background div.
 */
export function adjustHighlights(feedbacks: Feedback[]): Feedback[] {
  const markers = feedbacks.filter((f) => f.kind === FeedbackType.Marker);
  const highlights = feedbacks.filter((f) => f.kind === FeedbackType.Highlight);

  return highlights.map((highlight) => {
    return adjustHighlight(highlight, markers);
  });
}

/**
 * Adjusts the highlight positions (loc - attribute) depending on the number of markers before the highlight
 * @param highlight - The to be adjusted highlight
 * @param markers - The marker that have to be taken into account. This should only be relevant markers.
 * Returns a new Highlight element with the adjusted location attribute.
 */
export function adjustHighlight(
  highlight: Feedback,
  markers: Feedback[]
): Feedback {
  return {
    ...highlight,
    loc: {
      // we move the start depending on the number of markers before the start value
      start:
        highlight.loc.start +
        getNumberOfMarkersBeforePos(markers, highlight.loc.start) *
          MARKER_CHARS.length,
      // we move the end depending on the number of markers before the end value.
      end:
        highlight.loc.end +
        getNumberOfMarkersBeforePos(markers, highlight.loc.end) *
          MARKER_CHARS.length,
    },
  };
}

/**
 *
 * @param feedbacks
 * @param diffIndex
 * @param changedAmount
 */
export function moveFeedbacks(
  feedbacks: Feedback[],
  diffIndex: number,
  changedAmount: number
): Feedback[] {
  const affectedFeedbacks = feedbacks.filter((f) => f.loc.start >= diffIndex);
  const movedFeedbacks = affectedFeedbacks.map((f) => ({
    ...f,
    loc: { start: f.loc.start + changedAmount, end: f.loc.end + changedAmount },
  }));
  return feedbacks
    .filter((f) => f.loc.start < diffIndex)
    .concat(movedFeedbacks);
}

export function getNumberOfMarkerCharsBeforePos(
  text: string,
  position: number
): number {
  return getNumberOfRegexOccurences(
    text.substring(0, position),
    new RegExp(MARKER_CHAR, 'g')
  );
}
