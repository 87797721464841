import { FeedbackType } from '../global/enums';
import { getSizeForPosition } from '../global/util';
import { filterFeedbackByCriteria } from '../helpers/feedback-helper';
import { EssayContextActionTypes } from '../store/actions/essay-state-action';
import { useEssayContext } from '../store/contexts/essay-state-context';
import './editing-area.css';
import FeedbackContainer from './feedback-container';
import WritingInputSection from './writing-input-section';
import { Feedback, ICriteriaGroup } from '../global/interfaces';

interface IEditingAreaProps {
  selectedCriteria: string;
  criteriaGroups: ICriteriaGroup[];
}

function EditingArea({ selectedCriteria, criteriaGroups }: IEditingAreaProps) {
  const { state, dispatch } = useEssayContext();
  const currentlySelectedGroup = criteriaGroups.find((criteriaGroup) =>
    criteriaGroup.items.some((item) => item.criterionId === selectedCriteria)
  );

  function changeFeedbackForWritingSection(
    newFeedback: Feedback[],
    position: number
  ) {
    const afterFeedbacks = state.feedbacks
      .filter(
        (feedback) =>
          feedback.position !== position ||
          feedback.criterion !== selectedCriteria ||
          feedback.kind === FeedbackType.Common
      )
      .concat([...newFeedback]);
    dispatch({
      type: EssayContextActionTypes.SET_FEEDBACKS,
      payload: afterFeedbacks,
    });
  }
  const sections = Object.values(state.texts).map(
    (essayPartValue, position) => {
      return (
        <WritingInputSection
          headline={''}
          key={'WritingInputSection' + position}
          size={getSizeForPosition(position)}
          feedbacks={filterFeedbackByCriteria(
            state.feedbacks,
            position,
            selectedCriteria
          )}
          handleFeedbackChange={(newFeedback) =>
            changeFeedbackForWritingSection(newFeedback, position)
          }
          text={essayPartValue}
          handleTextChange={(text: string) => {
            dispatch({
              type: EssayContextActionTypes.SET_TEXT_PART,
              payload: { text, position },
            });
          }}
          enabled={
            currentlySelectedGroup?.relevantPositions.includes(position) ?? true
          }
        />
      );
    }
  );

  const deleteFeedback = (deleteFeedback: Feedback) => {
    dispatch({
      type: EssayContextActionTypes.SET_FEEDBACKS,
      payload: [...state.feedbacks, { ...deleteFeedback, isRemoved: true }],
    });
  };

  return (
    <div className="editingArea">
      {selectedCriteria !== '' ? (
        <div className="editingAreaContent">
          <FeedbackContainer
            feedback={state.feedbacks.filter(
              (feedback) => feedback.criterion === selectedCriteria
            )}
            deleteFeedback={deleteFeedback}
            question={
              currentlySelectedGroup?.items.find(
                (item) => item.criterionId === selectedCriteria
              )?.question ?? ''
            }
          />
          <hr className="defaultDelimiter" />
          {sections}
        </div>
      ) : (
        <div className="centeredMessage">Bitte wähle ein Kriterium aus :)</div>
      )}
    </div>
  );
}

export default EditingArea;
