import { ESSAYS } from '../../global/constants';
import { initialEssayPartsState } from '../../initialStates';
import { Essay, Feedback, IEssayParts } from '../../global/interfaces';

export interface IEssayState {
  texts: IEssayParts;
  feedbacks: Feedback[];
  essays: Essay[];
  selectedEssay: number;
  textChanged: boolean;
}

export const InitialEssayState: IEssayState = {
  texts: initialEssayPartsState,
  feedbacks: [],
  essays: ESSAYS,
  selectedEssay: 0,
  textChanged: false,
};

// for mock purposes
//export const InitialGlobalState: IEssayState = initGlobalStateMock;
