import './instruction.css';

interface IInstructionProps {
  headline: string;
  subheadline: string;
}

function Instruction({
  headline,
  subheadline,
}: IInstructionProps): JSX.Element {
  return (
    <aside className="instruction">
      <p className="slimText bold">{headline}</p>
      <p className="slimText disabledDescription">{subheadline}</p>
    </aside>
  );
}

export default Instruction;
