import React, { useEffect, useState } from 'react';
import { MARKER_CHARS } from '../global/constants';
import { FeedbackType } from '../global/enums';
import './writing-input-section.css';
import { Feedback } from '../global/interfaces';
import {
  getBackgroundText,
  getFirstDiffIndex,
  getNumberOfMarkerCharsBeforePos,
  getNumberOfMarkersBeforePos,
  insertMarkers,
  isHighlightAffected,
  isMarkerAffected,
  moveFeedbacks,
  removeMarkers,
} from '../helpers/marker-helper';
import { filterDuplicateFeedbacks } from '../helpers/feedback-helper';

export enum WritingSectionSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

interface IWritingInputSectionProps
  extends React.HTMLAttributes<HTMLTextAreaElement> {
  headline: string;
  size: WritingSectionSize;
  text: string;
  handleTextChange: (text: string) => void;
  feedbacks: Feedback[];
  handleFeedbackChange: (feedbacks: Feedback[]) => void;
  enabled: boolean;
}

function WritingInputSection({
  headline,
  size,
  text,
  handleTextChange,
  feedbacks,
  handleFeedbackChange,
  enabled,
}: IWritingInputSectionProps) {
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const highlightDiv = React.createRef<HTMLDivElement>();
  const textArea = React.createRef<HTMLTextAreaElement>();
  // we do this so the feedbacks are always with no duplicates and sorted.
  const polishedFeedbacks = filterDuplicateFeedbacks(feedbacks).sort((a, b) => {
    return a.loc.start - b.loc.start;
  });

  useEffect(() => {
    if (textArea.current) {
      textArea.current.selectionStart = cursorPosition;
      textArea.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition, textArea]);

  function handleTextAreaChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const changedTextAreaContent = event.target.value;
    handleTextAreaMarkerChange(changedTextAreaContent);
  }

  function handleTextAreaMarkerChange(changedTextAreaContent: string) {
    const oldTextWithMarkers = insertMarkers(text, polishedFeedbacks);
    const markerFeedbacks = polishedFeedbacks.filter(
      (feedback) => feedback.kind === FeedbackType.Marker
    );
    const highlightFeedbacks = polishedFeedbacks.filter(
      (feedback) => feedback.kind === FeedbackType.Highlight
    );

    const diffIndex = getFirstDiffIndex(
      oldTextWithMarkers,
      changedTextAreaContent
    );

    // the real before the variable means, that these values are without the marker chars.
    const realDiffIndex =
      diffIndex -
      getNumberOfMarkerCharsBeforePos(changedTextAreaContent, diffIndex);

    const realChangedCharAmount =
      removeMarkers(changedTextAreaContent).length - text.length;

    const changedCharAmount =
      changedTextAreaContent.length -
      text.length -
      markerFeedbacks.length * MARKER_CHARS.length;

    // remove the feedbacks which were affected
    // (e.g by marking the text and removing it, then the highlights inside this area should be removed, too)
    const unaffectedMarkers = markerFeedbacks.filter(
      (markerFeedback) =>
        !isMarkerAffected(
          markerFeedback,
          text,
          polishedFeedbacks,
          diffIndex,
          changedCharAmount
        )
    );

    const unaffectedHighlights = highlightFeedbacks.filter(
      (highlightFeedback) =>
        !isHighlightAffected(
          highlightFeedback,
          text,
          polishedFeedbacks,
          diffIndex,
          changedCharAmount
        )
    );

    // move the feedback positions
    const movedHighlights = moveFeedbacks(
      unaffectedHighlights,
      realDiffIndex,
      realChangedCharAmount
    );

    const movedMarkers = moveFeedbacks(
      unaffectedMarkers,
      realDiffIndex,
      realChangedCharAmount
    );

    handleFeedbackChange(movedMarkers.concat(movedHighlights));
    handleTextChange(removeMarkers(changedTextAreaContent));

    // set the cursor position which would be broken if we remove markers
    const realCursorPosition =
      realChangedCharAmount > 0
        ? realDiffIndex + realChangedCharAmount
        : realDiffIndex;
    const newCursorPosition =
      realCursorPosition +
      getNumberOfMarkersBeforePos(unaffectedMarkers, realCursorPosition) *
        MARKER_CHARS.length;
    setCursorPosition(newCursorPosition);
  }

  function handleTextAreaScroll(event: React.UIEvent<HTMLTextAreaElement>) {
    if (highlightDiv.current != null) {
      highlightDiv.current.scrollTop = event.currentTarget.scrollTop;
    }
  }

  return (
    <section className="mainInputSection">
      {headline !== '' && <p className="bold">{headline}</p>}
      {enabled ? (
        <div className={'container ' + size}>
          <div
            className="highlightArea"
            ref={highlightDiv}
            //we know, that this is bad. TODO: refactor!
            dangerouslySetInnerHTML={{
              __html: getBackgroundText(
                insertMarkers(text, polishedFeedbacks),
                polishedFeedbacks
              ),
            }}
          />
          <textarea
            className="mainTextArea"
            //onFocus={() => setFocused(true)}
            onChange={handleTextAreaChange}
            onScroll={handleTextAreaScroll}
            value={insertMarkers(text, polishedFeedbacks)}
            ref={textArea}
            spellCheck="false"
          />
        </div>
      ) : (
        <div>{text}</div>
      )}
    </section>
  );
}

export default WritingInputSection;
