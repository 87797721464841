import { ReactComponent as SpeechBubble } from '../icons/speechBubbleIcon.svg';
import { ReactComponent as QuestionMark } from '../icons/questionMarkIcon.svg';
import { ReactComponent as Cross } from '../icons/crossIcon.svg';
import './header.css';

interface IHeaderProps {
  headline: string;
  subheadline: string;
}

function Header({ headline, subheadline }: IHeaderProps): JSX.Element {
  return (
    <header className="headlineContainer">
      <p className="bold slimTextWithTopMargin noLineBreak">{headline}</p>
      <p className="disabledDescription slimTextWithTopMargin noLineBreak">
        {subheadline}
      </p>
      <button className="transparentButton">
        <SpeechBubble className="defaultSVG" />
        <p className="noLineBreak">Problem melden</p>
      </button>
      <button className="helpButton">
        <QuestionMark className="defaultSVG" />
        Hilfe
      </button>
      <button className="exitButton">
        <Cross className="bigSVG" />
      </button>
    </header>
  );
}

export default Header;
