import './footer.css';
import { ReactComponent as Keyboard } from '../icons/keyboardIcon.svg';
import { ReactComponent as Redo } from '../icons/redoIcon.svg';
import { ReactComponent as Undo } from '../icons/undoIcon.svg';

type FooterProps = {
  primaryButtonText: string;
  primaryButtonClick: () => void;
  secondaryButtonText: string;
  secondaryButtonClick: () => void;
};

function Footer({
  primaryButtonText,
  primaryButtonClick,
  secondaryButtonText,
  secondaryButtonClick,
}: FooterProps) {
  return (
    <footer className="controlContainer">
      <button className="keyboardButton">
        <Keyboard />
      </button>
      <button className="undoButton">
        <Undo />
      </button>
      <button className="redoButton">
        <Redo />
      </button>
      {secondaryButtonText != '' && (
        <button
          className="spellCheckButton"
          onClick={() => secondaryButtonClick()}
        >
          <p className="slimTextWithSmallTopMargin bold">
            {secondaryButtonText}
          </p>
        </button>
      )}
      <button className="correctionButton" onClick={() => primaryButtonClick()}>
        <p className="slimTextWithSmallTopMargin bold">{primaryButtonText}</p>
      </button>
    </footer>
  );
}

export default Footer;
