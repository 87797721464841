import { useEffect, useState } from 'react';
import NavBar from './components/nav-bar';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import WritingExercise from './components/writing-exercise';
import PostProcessingExercise from './components/post-processing-exercise';
import EssaySelector from './components/essay-selector';
import { EssayContextProvider } from './store/contexts/essay-state-context';
import Cookies from 'js-cookie';

import { ErrorBoundary } from './components/error-boundary';
import { useFetchCriteriaGroup } from './hooks/useFetchCriteriaGroup';
import { IVersionInformation } from './global/interfaces';

function App() {
  const { criteriaGroups, fetchCriteriaGroup } = useFetchCriteriaGroup();

  const routes = ['/select', '/write', '/post-process'];

  const [versionInformation, setVersionInformation] =
    useState<IVersionInformation>({
      lastCommit: '',
      commitDate: '',
    });

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const updateVersionInfo = (_versionInfo: any) => {
    if ('lastCommit' in _versionInfo) {
      setVersionInformation({
        ...versionInformation,
        lastCommit: _versionInfo.lastCommit,
      });
    }
    if ('commitDate' in _versionInfo) {
      setVersionInformation((v) => ({
        ...v,
        commitDate: _versionInfo.commitDate,
      }));
    }
  };

  const parseVersionInfo = (_versionInfo: IVersionInformation) => {
    return `last commit: ${_versionInfo.lastCommit} \n commit date: ${_versionInfo.commitDate}`;
  };
  // get the criteria
  useEffect(() => {
    fetchCriteriaGroup();

    const getData = () => {
      fetch('version.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          updateVersionInfo(myJson);
        });
    };
    getData();
  }, []);

  useEffect(() => {
    Cookies.set('DEBUG', '0');
  }, []);

  return (
    <div className="defaultContainer">
      <div className={'gitInfoBox'}>{parseVersionInfo(versionInformation)}</div>
      <BrowserRouter>
        <EssayContextProvider>
          <NavBar routes={routes} />
          <Routes>
            <Route path="*" element={<Navigate to={routes[0]} replace />} />
            <Route path={routes[0]} element={<EssaySelector />} />
            <Route
              path={routes[1]}
              element={
                <ErrorBoundary>
                  <WritingExercise />
                </ErrorBoundary>
              }
            />
            <Route
              path={routes[2]}
              element={
                <ErrorBoundary>
                  <PostProcessingExercise criteriaGroups={criteriaGroups} />
                </ErrorBoundary>
              }
            />
          </Routes>
        </EssayContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
