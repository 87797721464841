import { useState } from 'react';
import { ICriteriaGroup } from '../global/interfaces';
import { BACKEND_URL } from '../global/constants';

type FetchCriteriaGroupsType = {
  criteriaGroups: ICriteriaGroup[];
  fetchCriteriaGroup: () => void;
};

export function useFetchCriteriaGroup(): FetchCriteriaGroupsType {
  const [criteriaGroups, setCriteriaGroups] = useState<ICriteriaGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //mock start
  /*return {
    criteriaGroups: mockCriteriaGroups,
    fetchCriteriaGroup() {},
  };*/
  //mock end

  function fetchCriteriaGroup() {
    {
      setIsLoading(true);

      fetch(BACKEND_URL + 'criteria')
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setCriteriaGroups(data.criteria ?? []);
          setIsLoading(false);
        })
        .catch(() => {
          //console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }
  if (isLoading) {
    throw new Promise((resolve, reject) => {
      reject('loading');
    });
  }
  return { criteriaGroups, fetchCriteriaGroup };
}
