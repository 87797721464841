import { Essay, Feedback, IEssayParts } from '../../global/interfaces';

export enum EssayContextActionTypes {
  SET_FEEDBACKS = 'SET_FEEDBACKS',
  SET_SPECIFIC_FEEDBACKS = 'SET_SPECIFIC_FEEDBACKS',
  CLEAR_FEEDBACKS = 'CLEAR_FEEDBACKS',
  SET_TEXTS = 'SET_TEXTS',
  SET_TEXT_PART = 'SET_TEXT_PART',
  CLEAR_TEXTS = 'CLEAR_TEXTS',
  INIT_ESSAYS = 'INIT_ESSAYS',
  SET_SELECTED_ESSAY = 'SET_SELECTED_ESSAY',
  SET_TEXT_CHANGED_TRUE = 'SET_TEXT_CHANGED_TRUE',
  SET_TEXT_CHANGED_FALSE = 'SET_TEXT_CHANGED_FALSE',
}

export type EssayAction =
  | { type: EssayContextActionTypes.SET_FEEDBACKS; payload: Feedback[] }
  | {
      type: EssayContextActionTypes.SET_SPECIFIC_FEEDBACKS;
      payload: { feedbacks: Feedback[]; selectedCriterionId: string };
    }
  | { type: EssayContextActionTypes.SET_TEXTS; payload: IEssayParts }
  | { type: EssayContextActionTypes.CLEAR_FEEDBACKS }
  | {
      type: EssayContextActionTypes.SET_TEXT_PART;
      payload: { text: string; position: number };
    }
  | {
      type: EssayContextActionTypes.CLEAR_TEXTS;
    }
  | {
      type: EssayContextActionTypes.INIT_ESSAYS;
      payload: Essay[];
    }
  | {
      type: EssayContextActionTypes.SET_SELECTED_ESSAY;
      payload: number;
    }
  | {
      type: EssayContextActionTypes.SET_TEXT_CHANGED_TRUE;
    }
  | {
      type: EssayContextActionTypes.SET_TEXT_CHANGED_FALSE;
    };
