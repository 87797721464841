import React, { useContext, useReducer } from 'react';
import { EssayAction } from '../actions/essay-state-action';
import EssayReducer from '../reducers/essay-state-reducer';
import { IEssayState, InitialEssayState } from '../states/essay-state';

export type EssayDispatch = (action: EssayAction) => void;

const EssayContext = React.createContext<
  { state: IEssayState; dispatch: EssayDispatch } | undefined
>(undefined);

export function EssayContextProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [state, dispatch] = useReducer(EssayReducer, InitialEssayState);
  const value = { state, dispatch };
  return (
    <EssayContext.Provider value={value}>{children}</EssayContext.Provider>
  );
}

export function useEssayContext() {
  const context = useContext(EssayContext);
  if (context === undefined) {
    throw new Error(
      'useEssayContext must be used within a EssayContextProvider'
    );
  }
  return context;
}
