import { WritingSectionSize } from '../components/writing-input-section';

export function getSizeForPosition(position: number): WritingSectionSize {
  switch (position) {
    case 0:
      return WritingSectionSize.Small;
    case 2:
      return WritingSectionSize.Large;
    default:
      return WritingSectionSize.Medium;
  }
}

/*export function getHeadlineForPosition(position: number): string {
  switch (position) {
    case 0:
      return 'Überschrift';
    case 1:
      return 'Einleitung';
    case 2:
      return 'Hauptteil';
    case 3:
      return 'Schluss';
    default:
      return 'Unknown';
  }
}*/

export const getHeadlineForPosition = (position: number) =>
  safeGet(
    ['Überschrift', 'Einleitung', 'Hauptteil', 'Schluss'],
    position,
    'Unknown'
  );

function safeGet<T>(array: T[], position: number, errorValue: T) {
  if (position < 0 || position >= array.length) {
    return errorValue;
  } else {
    const value = array[position];
    return value === undefined ? errorValue : value;
  }
}
