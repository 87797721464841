import { FeedbackType } from '../global/enums';
import { Feedback } from '../global/interfaces';
import _ from 'lodash';

export function filterFeedbackByCriteria(
  feedbacks: Feedback[],
  position: number,
  selectedCriteria: string,
  negate = false
): Feedback[] {
  if (!negate) {
    return feedbacks.filter(
      (feedback) =>
        feedback.position === position &&
        feedback.criterion === selectedCriteria &&
        feedback.kind !== FeedbackType.Common
    );
  } else {
    return feedbacks.filter(
      (feedback) =>
        feedback.position !== position ||
        feedback.criterion !== selectedCriteria ||
        feedback.kind === FeedbackType.Common
    );
  }
}

export function filterDuplicateFeedbacks(feedbacks: Feedback[]): Feedback[] {
  const seenFeedback: Feedback[] = [];
  feedbacks.forEach((feedback) => {
    if (!arrayAlreadyHasFeedback(seenFeedback, feedback)) {
      seenFeedback.push(feedback);
    }
  });
  return seenFeedback;
}

export function arrayAlreadyHasFeedback(
  feedbackArray: Feedback[],
  feedback: Feedback
): boolean {
  for (let i = 0; i < feedbackArray.length; i++) {
    if (_.isEqual(feedbackArray[i], feedback)) {
      return true;
    }
  }
  return false;
}
