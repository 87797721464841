import './feedback-container.css';
import { ReactComponent as LoadspeakerIcon } from '../icons/loudspeaker.svg';
import { Feedback } from '../global/interfaces';

type FeedbackContainerProps = {
  feedback: Feedback[];
  deleteFeedback: (feedback: Feedback) => void;
  question: string;
};

function FeedbackContainer({
  feedback,
  deleteFeedback,
  question,
}: FeedbackContainerProps) {
  const uniqueFeedbacks = [
    ...new Map(feedback.map((item) => [item.message, item])).values(),
  ];
  //TODO: make seperate UserNotification Componentent & simplify logic...
  return (
    <>
      <p className="slimText">{question}</p>
      {uniqueFeedbacks.length === 0 ? (
        <div className="userNotification success">
          <p>
            Super!
            <br />
            In diesem Kriterium hast du alles richtig gemacht!
          </p>
          <button className="imageButton">
            <LoadspeakerIcon />
          </button>
        </div>
      ) : uniqueFeedbacks.every((feedback) => feedback.isRemoved == true) ? (
        <div className="userNotification info">
          <p>
            Du hast die in diesem Kriterium anfallenden Feedbacks überarbeitet.
            <br />
            Überprüfe das Kriterium erneut, um festzustellen ob nun alles
            richtig ist.
          </p>
          <button className="imageButton">
            <LoadspeakerIcon />
          </button>
        </div>
      ) : (
        uniqueFeedbacks
          .filter(
            (feedback) =>
              (feedback.isRemoved === false ||
                feedback.isRemoved === undefined) &&
              feedback.message
          )
          .map((uniqueFeedback, index) => (
            <div
              className="userNotification warning"
              key={'userNotification' + index}
            >
              <p>{uniqueFeedback.message}</p>
              <button className="imageButton">
                <LoadspeakerIcon />
              </button>
              <button
                className="textButton"
                onClick={() => deleteFeedback(uniqueFeedback)}
              >
                <p>Ignorieren</p>
              </button>
            </div>
          ))
      )}
    </>
  );
}

export default FeedbackContainer;
