import Footer from './footer';
import Header from './header';
import Instruction from './instruction';
import './writing-exercise.css';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './loading-spinner';
import InputAreaContent from './input-area';
import { useFetchFeedback } from '../hooks/useFetchFeedback';
import { useEssayContext } from '../store/contexts/essay-state-context';

function WritingExercise() {
  const navigate = useNavigate();

  const { isLoading, fetchFeedback, errorMessage } = useFetchFeedback();
  if (errorMessage !== '') {
    throw new Error(errorMessage);
  }
  const { state, dispatch } = useEssayContext();

  const getFeedbacks = () => {
    // get feedback for spelling only
    fetchFeedback(
      state.texts,
      state.essays[state.selectedEssay],
      dispatch,
      //TODO: this selected criteria should be done dynamically
      'SPELLING'
    );
  };

  return (
    <div className="mainContent">
      {
        <div>
          <Header
            headline="Schritt 1"
            subheadline="Vorgangsbeschreibung verfassen"
          />

          <Instruction
            headline="Verfasse die Vorgangsbeschreibung."
            subheadline="Nutze deinen Schreibplan, damit du nichts Wichtiges vergisst."
          />
        </div>
      }

      <div className="inputContainer" style={{}}>
        <div className="mainInputArea">
          <InputAreaContent />
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
      <Footer
        primaryButtonText="Überarbeiten"
        secondaryButtonText="Rechtschreibung prüfen"
        primaryButtonClick={() => {
          navigate('/post-process');
        }}
        secondaryButtonClick={() => {
          getFeedbacks();
        }}
      />
    </div>
  );
}

export default WritingExercise;
