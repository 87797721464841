import { NavLink } from 'react-router-dom';
import './nav-bar.css';

function NavBar({ routes }: { routes: string[] }) {
  return (
    <nav className="navBar">
      {routes.map((value: string, index: number) => (
        <NavLink
          key={'navBarItem' + index}
          className={({ isActive }) => 'step' + (isActive ? ' selected' : '')}
          to={value}
        >
          <p className="slimTextWithTopMargin">{index}</p>
        </NavLink>
      ))}
    </nav>
  );
}

export default NavBar;
