import './criteria-overview.css';
import { ReactComponent as CheckMark } from '../icons/check.svg';
import { ReactComponent as Dot } from '../icons/dot.svg';
import { ReactComponent as ArrowRight } from '../icons/arrowRight.svg';
import React, { useEffect } from 'react';
import { CriteriaItem, Feedback, ICriteriaGroup } from '../global/interfaces';
import { useEssayContext } from '../store/contexts/essay-state-context';

export interface ICriteriaOverviewProps {
  criteriaGroup: ICriteriaGroup[];
  selectedCriteria: string;
  setSelectedCriteria: (criteriaId: string) => void;
}

function CriteriaOverview({
  criteriaGroup,
  selectedCriteria,
  setSelectedCriteria,
}: ICriteriaOverviewProps) {
  const { state } = useEssayContext();

  // select the first element
  useEffect(() => {
    if (criteriaGroup.length > 0 && criteriaGroup[0].items.length > 0) {
      const firstCriteriaElement = criteriaGroup[0].items[0].criterionId ?? '';
      setSelectedCriteria(firstCriteriaElement);
    }
  }, [criteriaGroup]);

  const criteriaElements = criteriaGroup.reduce(
    (accumulator, currentValue) => [
      ...accumulator,
      currentValue,
      currentValue.items,
    ],
    [] as (ICriteriaGroup | CriteriaItem[])[]
  );

  const fillColorClassForGroup = (
    currentFeedbacks: Feedback[],
    item: ICriteriaGroup
  ) =>
    currentFeedbacks
      .map((feedback) => feedback.criterion)
      .some((feedback) =>
        item.items.map((item) => item.criterionId).includes(feedback)
      )
      ? 'wrongFill'
      : 'rightFill';

  const indicatorSVGForItem = (
    currentFeedbacks: Feedback[],
    item: CriteriaItem
  ) =>
    currentFeedbacks.some(
      (feedback) => feedback.criterion === item.criterionId
    ) ? (
      currentFeedbacks
        .filter((feedback) => feedback.criterion === item.criterionId)
        .every((feedback) => feedback.isRemoved === true) ? (
        <Dot className="highlightFill" />
      ) : (
        <Dot className="highlightFill" />
      )
    ) : (
      <CheckMark className="rightFill" />
    );

  // we use this because the onclick event of the radio button is registered later then the onblur event of the textareas
  // also there is no working onmousedown event on the input so we have to use the label onmousedown event
  const changeLabelCriteriaOnMouse = (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>,
    criteriaId: string
  ) => {
    setSelectedCriteria(criteriaId);
  };

  const isChecked = (currentCriterion: string) => {
    return currentCriterion === selectedCriteria;
  };

  // TODO: remove the radio buttons and replace with nested routes.
  return (
    <fieldset className="criteriaOverview">
      {criteriaElements.map((item, index) => {
        if (!Array.isArray(item)) {
          return (
            <div
              className={
                'criteriaHeadlineItem ' +
                fillColorClassForGroup(state.feedbacks, item)
              }
              key={'headlineItem' + index}
            >
              <CheckMark />
              <p>{item.name}</p>
            </div>
          );
        } else {
          return (
            <div
              className="criteriaOptionWrapper"
              key={'criteriaOptionWrapper' + index}
            >
              {item.map((criteriaItem, subIndex) => [
                <input
                  type="radio"
                  name="criteria"
                  id={'criteria' + index + '-' + subIndex}
                  key={'radio' + subIndex}
                  value={criteriaItem.criterionId}
                  checked={isChecked(criteriaItem.criterionId)}
                />,
                <label
                  className={'criteriaOptionItem'}
                  htmlFor={'criteria' + index + '-' + subIndex}
                  key={'label' + subIndex}
                  onMouseDown={(event) =>
                    changeLabelCriteriaOnMouse(event, criteriaItem.criterionId)
                  }
                >
                  {indicatorSVGForItem(state.feedbacks, criteriaItem)}
                  <p>{criteriaItem.name}</p>
                  <ArrowRight />
                </label>,
              ])}
            </div>
          );
        }
      })}
    </fieldset>
  );
}

export default CriteriaOverview;
