import './side-section.css';
import CriteriaOverview from './criteria-overview';
import { ICriteriaGroup } from '../global/interfaces';

interface ISideSectionProps {
  criteriaGroups: ICriteriaGroup[];
  selectedCriteria: string;
  setSelectedCriteria: (criteriaId: string) => void;
}

function SideSection({
  criteriaGroups,
  selectedCriteria,
  setSelectedCriteria,
}: ISideSectionProps) {
  return (
    <aside className="sideMenu">
      <div className="contentWrapper">
        <div className="innerContentWrapper">
          <CriteriaOverview
            criteriaGroup={criteriaGroups}
            selectedCriteria={selectedCriteria}
            setSelectedCriteria={setSelectedCriteria}
          />
        </div>
      </div>
    </aside>
  );
}

export default SideSection;
