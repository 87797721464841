import { Essay, IWritingPlan } from '../global/interfaces';
import Cookies from 'js-cookie';

export function constructWritingPlan(
  essays: Essay[],
  selectedEssay: number
): IWritingPlan {
  return {
    requiredItems: essays[selectedEssay].introduction.materials.map(
      (material) => material[0]
    ),
    steps: essays[selectedEssay].mainpart.steps.map((step) => step[0]),
    endDescription: essays[selectedEssay].ending.warning,
  };
}

/**
 * Downloads a file
 * @param data The string that shall stand in the file (If it is a json you must stringify the json first)
 * @param fileName The name of the file (dont forget the extension)
 * @param fileType The type of the file (e.g. text/json, text/csv)
 */
export function downloadFile(data: string, fileName: string, fileType: string) {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
}

export function isInDebugMode() {
  const debugMode = Cookies.get('DEBUG') ?? '0';
  return debugMode === '1';
}
