import { MARKER_CHARS } from '../../global/constants';
import { initialEssayPartsState } from '../../initialStates';
import {
  EssayAction,
  EssayContextActionTypes,
} from '../actions/essay-state-action';
import { IEssayState } from '../states/essay-state';

const EssayReducer = (state: IEssayState, action: EssayAction): IEssayState => {
  switch (action.type) {
    case EssayContextActionTypes.SET_FEEDBACKS: {
      return {
        ...state,
        feedbacks: action.payload,
      };
    }
    case EssayContextActionTypes.SET_SPECIFIC_FEEDBACKS: {
      if (action.payload.feedbacks.length < 1) {
        return state;
      }
      const filteredFeedbacks = state.feedbacks.filter(
        (feedback) => feedback.criterion !== action.payload.selectedCriterionId
      );
      const addedFeedbacks = filteredFeedbacks.concat(
        action.payload.feedbacks.filter(
          (feedback) =>
            feedback.criterion === action.payload.selectedCriterionId
        )
      );
      return {
        ...state,
        feedbacks: addedFeedbacks,
      };
    }
    case EssayContextActionTypes.SET_TEXTS: {
      return {
        ...state,
        texts: action.payload,
        textChanged: true,
      };
    }
    case EssayContextActionTypes.CLEAR_FEEDBACKS: {
      return {
        ...state,
        feedbacks: [],
      };
    }
    case EssayContextActionTypes.CLEAR_TEXTS: {
      return {
        ...state,
        texts: initialEssayPartsState,
        textChanged: true,
      };
    }
    case EssayContextActionTypes.INIT_ESSAYS: {
      return {
        ...state,
        essays: action.payload,
      };
    }
    case EssayContextActionTypes.SET_SELECTED_ESSAY: {
      return {
        ...state,
        selectedEssay: action.payload,
      };
    }
    case EssayContextActionTypes.SET_TEXT_PART: {
      const newText = action.payload.text.replace(MARKER_CHARS, '');

      switch (action.payload.position) {
        case 0:
          return {
            ...state,
            texts: { ...state.texts, heading: newText },
            textChanged: true,
          };
        case 1:
          return {
            ...state,
            texts: { ...state.texts, introduction: newText },
            textChanged: true,
          };
        case 2:
          return {
            ...state,
            texts: { ...state.texts, mainPart: newText },
            textChanged: true,
          };
        case 3:
          return {
            ...state,
            texts: { ...state.texts, ending: newText },
            textChanged: true,
          };
      }
      return state;
    }
    case EssayContextActionTypes.SET_TEXT_CHANGED_TRUE: {
      return { ...state, textChanged: true };
    }
    case EssayContextActionTypes.SET_TEXT_CHANGED_FALSE: {
      return { ...state, textChanged: false };
    }

    default: {
      return state;
    }
  }
};

export default EssayReducer;
