import { useState } from 'react';
import { BACKEND_URL } from '../global/constants';
import { Essay, IEssayParts } from '../global/interfaces';
import { EssayContextActionTypes } from '../store/actions/essay-state-action';
import { EssayDispatch } from '../store/contexts/essay-state-context';
import { downloadFile, isInDebugMode } from '../helpers/helpers';

type FetchFeedbackType = {
  isLoading: boolean;
  fetchFeedback: (
    _texts: IEssayParts,
    config: Essay,
    dispatch: EssayDispatch,
    selectedCriteria: string | undefined
  ) => void;
  errorMessage: string;
};

export function useFetchFeedback(): FetchFeedbackType {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // mock start
  /*return {
      isLoading,
      fetchFeedback(_texts, config, dispatch) {
        dispatch({
          type: EssayContextActionTypes.SET_FEEDBACKS,
          payload: mockFeedbacks,
        });
      },
      errorMessage: '',
    };*/

  // mock end
  async function fetchFeedback(
    texts: IEssayParts,
    config: Essay,
    dispatch: EssayDispatch,
    selectedCriteria: string | undefined
  ) {
    {
      //console.log('posting feedback to:', BACKEND_URL);
      setIsLoading(true);
      // insert selected criteria if it is not undefined
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          configuration: config,
          texts: Object.values(texts),
        }),
      };
      if (selectedCriteria !== undefined) {
        requestOptions = {
          ...requestOptions,
          body: JSON.stringify({
            configuration: config,
            texts: Object.values(texts),
            criterionId: selectedCriteria,
          }),
        };
      }
      fetch(BACKEND_URL + 'feedback', requestOptions)
        .then((response) => {
          if (!response.ok) {
            setErrorMessage(
              'Could not get a response from the server. Try reloading after some time.'
            );
            return;
          }
          return response.json();
        })
        .then((data) => {
          if (data.feedbacks === undefined) {
            //console.log('data is undefined', data);
          }
          if ('explanation' in data) {
            // eslint-disable-next-line no-console
            console.log(
              `Feedback explanation (${new Date().toLocaleString()}):`
            );
            // eslint-disable-next-line no-console
            console.log(data.explanation);
            if (isInDebugMode()) {
              downloadFile(
                JSON.stringify(data.explanation),
                'explanation.json',
                'text/json'
              );
            }
          }
          setErrorMessage('');
          if (selectedCriteria !== undefined) {
            dispatch({
              type: EssayContextActionTypes.SET_SPECIFIC_FEEDBACKS,
              payload: {
                feedbacks: data.feedbacks ?? [],
                selectedCriterionId: selectedCriteria,
              },
            });
          } else {
            dispatch({
              type: EssayContextActionTypes.SET_FEEDBACKS,
              payload: data.feedbacks ?? [],
            });
          }
        })
        .catch(() => {
          /*setErrorMessage(
                        `Could not get a response from the server. Try reloading after some time. ${e}`
                    );*/
          return [];
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  /** This is for later if we want to throw promises to the suspense object that catches them
     if (isLoading) {
        throw new Promise((resolve, reject) => {
            reject('loading');
        });
    }
     */
  return { isLoading, fetchFeedback, errorMessage };
}
