import { Configuration, Essay } from './interfaces';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const MARKER_CHAR = '\u2002'; // you can also take unicode '█' (\u2588) or 🟡
export const MARKER_CHARS = MARKER_CHAR.repeat(5); // you can also take unicode '█' (\u2588) or 🟡

export const ESSAYS: Essay[] = require('./../essays.json').essays;

export const CONFIGURATION: Configuration = require('./../config.json');
