import { useNavigate } from 'react-router-dom';
import './essay-selector.css';
import Footer from './footer';
import Header from './header';
import Instruction from './instruction';
import { ReactComponent as ArrowRight } from '../icons/arrowRight.svg';
import { useEssayContext } from '../store/contexts/essay-state-context';
import { EssayContextActionTypes } from '../store/actions/essay-state-action';

function EssaySelector() {
  const { state, dispatch } = useEssayContext();
  const navigate = useNavigate();

  function changeSelectedEssay(newEssayIndexString: string) {
    try {
      const newEssayIndex = parseInt(newEssayIndexString);
      dispatch({ type: EssayContextActionTypes.CLEAR_TEXTS });
      dispatch({ type: EssayContextActionTypes.CLEAR_FEEDBACKS });
      dispatch({
        type: EssayContextActionTypes.SET_SELECTED_ESSAY,
        payload: newEssayIndex,
      });
    } catch (err) {
      //console.error('Could not parse the given event value.');
    }
  }

  return (
    <div className="mainContent">
      <Header headline="Schritt 1" subheadline="Thema auswählen" />
      <Instruction
        headline="Suche dir ein Thema aus."
        subheadline="Du hast die Möglichkeit dir ein beliebiges Thema auszusuchen, zu dem du eine Vorgangsbeschreibung verfassen möchtest."
      />
      <div className="inputContainer">
        <div className="selectionArea">
          <p className="bold">Themen:</p>
          <div className="criteriaOptionWrapper">
            {state.essays.map((essay, index) => [
              <input
                type="radio"
                name="criteria"
                id={'criteria' + index}
                key={'criteria' + index}
                value={index}
                checked={index === state.selectedEssay}
                onChange={(event) => {
                  changeSelectedEssay(event.currentTarget.value);
                }}
              />,
              <label
                className="criteriaOptionItem"
                htmlFor={'criteria' + index}
                key={'label' + index}
              >
                <p>{essay.title.variants[0]}</p>
                <ArrowRight />
              </label>,
            ])}
          </div>
        </div>
        <div className="detailsArea">
          <p className="bold">Informationsquellen:</p>
          <p>
            <a
              href={state.essays[state.selectedEssay].mainpart.videolink}
              target="_blank"
              rel="noreferrer"
            >
              Video
            </a>
            <br />
          </p>
        </div>
      </div>
      <Footer
        primaryButtonText="Schreiben beginnen"
        secondaryButtonText=""
        primaryButtonClick={() => {
          navigate('/write');
        }}
        secondaryButtonClick={() => {}} // eslint-disable-line
      />
    </div>
  );
}
export default EssaySelector;
