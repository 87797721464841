import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditingArea from './editing-area';
import { ErrorBoundary } from './error-boundary';
import Footer from './footer';
import Header from './header';
import Instruction from './instruction';
import LoadingSpinner from './loading-spinner';
import SideSection from './side-section';
import { ICriteriaGroup } from '../global/interfaces';
import { useFetchFeedback } from '../hooks/useFetchFeedback';
import { useEssayContext } from '../store/contexts/essay-state-context';
import { EssayContextActionTypes } from '../store/actions/essay-state-action';

interface IPostProcessingExerciseProps {
  criteriaGroups: ICriteriaGroup[];
}

function PostProcessingExercise({
  criteriaGroups,
}: IPostProcessingExerciseProps) {
  const { state, dispatch } = useEssayContext();
  const { isLoading, fetchFeedback, errorMessage } = useFetchFeedback();
  const navigate = useNavigate();

  const [selectedCriteria, setSelectedCriteria] = useState<string>('');

  if (errorMessage !== '') {
    throw new Error(errorMessage);
  }

  useEffect(() => {
    function getAllFeedbacks() {
      fetchFeedback(
        state.texts,
        state.essays[state.selectedEssay],
        dispatch,
        undefined
      );
    }

    dispatch({ type: EssayContextActionTypes.SET_TEXT_CHANGED_FALSE });
    getAllFeedbacks();
  }, []);

  const setNewSelectedCriteria = (newCriterionId: string) => {
    setSelectedCriteria(newCriterionId);
    getSpecificFeedbacks(newCriterionId);
  };

  function getSpecificFeedbacks(specificCriterion: string) {
    if (!state.textChanged) {
      return;
    }
    fetchFeedback(
      state.texts,
      state.essays[state.selectedEssay],
      dispatch,
      specificCriterion
    );
  }

  const currentlySelectedCriterion: string =
    criteriaGroups
      .map((criteriaGroup) => criteriaGroup.items)
      .flat()
      .find((criteria) => criteria.criterionId === selectedCriteria)?.name ??
    'Alles';

  return (
    <div className="mainContent">
      {
        <>
          <Header
            headline="Schritt 2"
            subheadline="Vorgangsbeschreibung überarbeiten"
          />
          <Instruction
            headline="Überarbeite deine Vorgangsbeschreibung."
            subheadline="Vergiss nicht, den Schreibplan als Hilfestellungzu nutzen."
          />
        </>
      }

      <div className="inputContainer">
        <SideSection
          criteriaGroups={criteriaGroups}
          selectedCriteria={selectedCriteria}
          setSelectedCriteria={setNewSelectedCriteria}
        />
        <ErrorBoundary>
          <EditingArea
            selectedCriteria={selectedCriteria}
            criteriaGroups={criteriaGroups}
          />
          {isLoading && <LoadingSpinner />}
        </ErrorBoundary>
      </div>
      <Footer
        primaryButtonText="Abgeben"
        secondaryButtonText={currentlySelectedCriterion + ' erneut prüfen'}
        primaryButtonClick={() => {
          navigate('/select');
        }}
        secondaryButtonClick={() => {
          getSpecificFeedbacks(selectedCriteria);
        }}
      />
    </div>
  );
}

export default PostProcessingExercise;
