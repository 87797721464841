import { useEssayContext } from '../store/contexts/essay-state-context';
import { EssayContextActionTypes } from '../store/actions/essay-state-action';
import { FeedbackType } from '../global/enums';
import { getHeadlineForPosition, getSizeForPosition } from '../global/util';
import WritingInputSection from './writing-input-section';
import styles from './input-area.module.css';
import { Feedback } from '../global/interfaces';

function InputAreaContent() {
  const { state, dispatch } = useEssayContext();
  const spellingCriterionId = 'SPELLING';

  const addFilteredFeedbacks = (newFeedbacks: Feedback[], position: number) => {
    const feedbacks = state.feedbacks
      .filter(
        (feedback) =>
          !(
            feedback.kind === FeedbackType.Highlight &&
            feedback.criterion === spellingCriterionId &&
            feedback.position === position
          )
      )
      .concat(newFeedbacks);

    dispatch({
      type: EssayContextActionTypes.SET_FEEDBACKS,
      payload: feedbacks,
    });
  };

  return (
    <div className={styles.mainInputAreaContent}>
      {Object.values(state.texts).map((essayPartValue, position) => (
        <WritingInputSection
          key={'WritingInputSection' + position}
          headline={getHeadlineForPosition(position)}
          size={getSizeForPosition(position)}
          feedbacks={state.feedbacks.filter(
            (feedback) =>
              feedback.kind === FeedbackType.Highlight &&
              feedback.criterion === spellingCriterionId &&
              feedback.position === position
          )}
          handleFeedbackChange={(newFeedbacks: Feedback[]) =>
            addFilteredFeedbacks(newFeedbacks, position)
          }
          text={essayPartValue}
          handleTextChange={(text: string) => {
            dispatch({
              type: EssayContextActionTypes.SET_TEXT_PART,
              payload: { text, position },
            });
          }}
          enabled
        />
      ))}
    </div>
  );
}

export default InputAreaContent;
